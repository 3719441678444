import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {AuthGuard} from "@app/guard/auth.guard";
import {TranslateModule} from "@ngx-translate/core";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {initializeKeycloak} from "@app/initializer/keycloak.initializer";
import {LangInterceptor} from "@app/interceptor/lang.interceptor";
import localeFr from '@angular/common/locales/fr';
import {SharedModule} from "@shared/shared.module";
import {GlobalErrorHandler} from "@app/handler/global-error-handler";

registerLocaleData(localeFr);

@NgModule({ imports: [CommonModule,
        KeycloakAngularModule,
        SharedModule,
        TranslateModule.forRoot()], providers: [
        {
            provide: LOCALE_ID,
            useValue: 'fr-FR'
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LangInterceptor,
            multi: true
        },
        {
            // processes all errors
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        KeycloakService,
        AuthGuard,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class CoreModule {
}
