import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationFormat',
  pure: false
})
export class DurationFormatPipe implements PipeTransform {

  transform(value: any, arg1: any, arg2: any): any {

    let days: number;
    let seconds: number;
    let minutes: number;
    let hours: number;

    if (arg1 === 'ms' && arg2 === 'hhmmss') {
      seconds = Math.floor((value / 1000) % 60);
      minutes = Math.floor((value / (1000 * 60)) % 60);
      hours   = Math.floor((value / (1000 * 60 * 60)));
      return this.format(arg2, seconds, minutes, hours, 0);

    } else if (arg1 === 's' && arg2 === 'hhmmss') {
      seconds = Math.floor((value % 60));
      minutes = Math.floor((value / 60) % 60);
      hours   = Math.floor((value / 60) / 60);
      return this.format(arg2, seconds, minutes, hours, 0);

    } else if (arg1 === 'ms' && (arg2 === 'ddhhmmss' || arg2 === 'ddhhmmssLong') ) {
      seconds = Math.floor((value / 1000) % 60);
      minutes = Math.floor((value / (1000 * 60)) % 60);
      hours   = Math.floor((value / (1000 * 60 * 60)) % 24);
      days    = Math.floor(value / (1000 * 60 * 60 * 24));
      return this.format(arg2, seconds, minutes, hours, days);

    } else if (arg1 === 's' && (arg2 === 'ddhhmmss' || arg2 === 'ddhhmmssLong') ) {
      seconds = Math.floor(value % 60);
      minutes = Math.floor((value / 60) % 60);
      hours   = Math.floor((value / 60 / 60) % 24);
      days    = Math.floor(value / 60 / 60 / 24);
      return this.format(arg2, seconds, minutes, hours, days);

    }
    else {
      return value;
    }
  }

  private format(arg2: any, seconds: number, minutes: number, hours: number, days: number): string {
    const formattedDays = days < 10 ? '0' + days : days.toString();
    const formattedHours = hours < 10 ? '0' + hours : hours.toString();
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds.toString();

    switch (arg2) {
      case 'hhmmss':
        return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;

      case 'ddhhmmss':
        return `${formattedDays}d, ${formattedHours}h, ${formattedMinutes}m, ${formattedSeconds}s`;

      case 'ddhhmmssLong':
        return `${formattedDays} days, ${formattedHours} hours, ${formattedMinutes} minutes, ${formattedSeconds} seconds`;

      default:
        return 'error';
    }
  }
}
