import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideNavComponent} from './side-nav/side-nav.component';
import {HeaderComponent} from './header/header.component';
import {SharedModule} from "@shared/shared.module";
import {RouterModule} from "@angular/router";
import {MatRipple} from "@angular/material/core";


@NgModule({
  declarations: [
    SideNavComponent,
    HeaderComponent
  ],
  exports: [
    SideNavComponent,
    HeaderComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        MatRipple,
    ]
})
export class LayoutModule {
}
