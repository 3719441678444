import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import {Observable, of} from "rxjs";
import {ToastService} from "@shared/services/toast.service";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private _translationService: TranslateService,
    private _toastService: ToastService) {
  }

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string | undefined {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): Observable<any> {

    let key;

    if (error?.error?.message) {
      return of(error.error.message);
    } else if (error?.status !== null) {
      key = "ERROR.SERVER.STATUS." + error.status;
    } else {
      key = "ERROR.SERVER.STATUS.0"
    }

    return this._translationService.get(key);
  }

  getServerStack(error: HttpErrorResponse): string {
    // handle stack trace
    return 'stack';
  }
}
