import {locale as englishTranslation} from '@app/i18n/en';
import {locale as frenchTranslation} from '@app/i18n/fr';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {environment} from "@env/environment";
import {TranslationLoaderService} from "@app/service/translation-loader.service";
import {LayoutService} from "@layout/service/layout.service";
import {Subject, takeUntil} from "rxjs";
import {AuthService} from "@app/service/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  $destroy: Subject<void> = new Subject<void>();
  status: string;
  isMobile: boolean;

  constructor(private authService: AuthService,
              private translateService: TranslateService,
              private layoutService: LayoutService,
              private translationLoaderService: TranslationLoaderService) {

  }


  ngOnInit(): void {
    this.initI18n();
    this.layoutService.isMobileSubject
      .pipe(takeUntil(this.$destroy))
      .subscribe((isMobile) => this.isMobile = isMobile);
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  private initI18n() {
    this.translateService.addLangs(['en', 'fr']);
    this.translateService.setDefaultLang(environment.defaultLocale)
    this.translationLoaderService.loadTranslations(englishTranslation, frenchTranslation);

    this.authService.user$
      .pipe(takeUntil(this.$destroy))
      .subscribe(user => {
        if (user) {
          console.log('user', user);
          let locale = user?.attributes?.locale ? user?.attributes?.locale[0] : environment.defaultLocale;
          console.log('locale', locale);
          this.translateService.use(locale);
        }
      });
  }

}
