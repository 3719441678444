import {Injectable} from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
// import {I18nService} from "@app/service/i18n.service";
import {Observable} from "rxjs";

@Injectable()
export class LangInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // return this.localeService.getCurrentLocale().pipe(
    //   first(),
    //   mergeMap(currentLocale => {
    //       if (currentLocale && !request.headers.has('Accept-Language')) {
    //         request = request.clone({
    //           setHeaders: {
    //             'Accept-Language': currentLocale
    //           }
    //         });
    //       }
    //       return next.handle(request);
    //     }
    //   )
    // );
    return next.handle(request);
  }


}


