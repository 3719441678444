import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "@app/guard/auth.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/rovers',
    pathMatch: 'full'
  },
  {
    path: 'rovers',
    loadChildren: () => import('./modules/rovers/rovers.module').then(m => m.RoversModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'missions',
    loadChildren: () => import('./modules/missions/missions.module').then(m => m.MissionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['ADMIN']
    }
  },
  {
    path: 'explore',
    loadChildren: () => import('./modules/explore/explore.module').then(m => m.ExploreModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'clusters',
    loadChildren: () => import('./modules/clusters/clusters.module').then(m => m.ClustersModule),
    canActivate: [AuthGuard],
    data: {
      roles: ['ADMIN', 'OPERATOR']
    }
  },
  {
    path: 'organizations',
    loadChildren: () => import('./modules/organizations/organizations.module').then(m => m.OrganizationsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'agronomy',
    loadChildren: () => import('./modules/agronomy/agronomy.module').then(m => m.AgronomyModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'logs',
    loadChildren: () => import('./modules/logs/logs.module').then(m => m.LogsModule),
    canActivate: [AuthGuard]
  },
  // When the URL doesn't match with any route, redirect to rovers path
  {
    path:'**',
    redirectTo: '/rovers',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
